<template>
	<v-sheet class="member px-3" style="height: calc(100vh - 88px); overflow: auto">
		<v-row>
			<v-col cols="12" class="pt-2 listing-right d-flex justify-content-between">
				<div class="my-auto px-2">
					<h4 class="fw-600">My Cart</h4>
				</div>
				<div>
					<v-btn
						v-on:click="
							requestType = 'checkout';
							cartUuid = null;
							selectRelateddialog = true;
						"
						color="blue darken-4 text-white"
						class="mx-2"
						depressed
						tile
					>
						<v-icon small left>mdi-plus</v-icon>
						Issue
					</v-btn>
					<v-btn
						v-on:click="
							requestType = 'checkin';
							cartUuid = null;
							selectRelateddialog = true;
						"
						color="blue darken-4 text-white"
						class="mx-2"
						depressed
						tile
					>
						<v-icon small left>mdi-plus</v-icon>
						Receive
					</v-btn>
					<v-btn
						v-if="false"
						v-on:click="
							requestType = 'reservation';
							cartUuid = null;
							selectRelateddialog = true;
						"
						color="blue darken-4 text-white"
						class="mx-2"
						depressed
						tile
					>
						<v-icon small left>mdi-plus</v-icon>
						Reservation
					</v-btn>
					<v-menu v-if="false" offset-y left bottom content-class="elevation-3" rounded="0">
						<template v-slot:activator="{ on, attrs }">
							<v-btn color="blue darken-4 text-white" v-on="on" v-bind="attrs" class="mx-2" depressed tile
								><v-icon left small>mdi-more </v-icon>More</v-btn
							>
						</template>
						<v-list>
							<v-list-item
								v-on:click="
									selectRelateddialog = true;
									requestType = 'scheduled';
								"
							>
								<v-list-item-title v-on:click="close_dialog = true">
									<v-icon small left color="blue darken-4">mdi-email</v-icon>
									Send as Email</v-list-item-title
								>
							</v-list-item>
							<v-list-item v-on:click="$router.push('checkout-approve')">
								<v-list-item-title>
									<v-icon small left color="blue darken-4">mdi-draw</v-icon>
									Signature Link</v-list-item-title
								>
							</v-list-item>
						</v-list>
					</v-menu>
					<v-btn
						v-if="
							checkouts.length > 0 || reservation.length > 0 || checkin.length > 0 || retired.length > 0
						"
						color="blue darken-4 text-white"
						class="mx-2"
						depressed
						tile
						v-on:click="proceedDialog = true"
					>
						<v-icon small left>mdi-share</v-icon>
						Proceed
					</v-btn>
					<v-btn :disabled="pageLoading" depressed tile v-on:click="goBack()"> Cancel </v-btn>
				</div>
			</v-col>
		</v-row>
		<!--  -->
		<v-row>
			<v-col cols="12" lg="9" class="custom-checkout-height">
				<v-layout
					v-if="
						checkouts.length > 0 || reservation.length > 0 || checkin.length > 0 || retired.length > 0
					"
					class="flex-wrap mx-2 mb-3 px-2 py-2 mx-md-4 align-center blue lighten-4"
				>
					<div class="d-flex align-center pr-10">
						<v-avatar class="border">
							<ImageTemplate circle :src="user.image"></ImageTemplate>
						</v-avatar>
						<h4 class="mb-0 ml-3">{{ user.user_name }}</h4>
					</div>
					<div class="pr-10 font-size-14">
						<v-icon size="20" color="blue darken-4">mdi-email</v-icon>
						{{ user.email }}
					</div>
					<div class="pr-10 font-size-14">
						<v-icon size="20" color="blue darken-4">mdi-phone</v-icon>
						{{ user.phone }}
					</div>
				</v-layout>
				<perfect-scrollbar :options="{ suppressScrollX: true }" class="scroll" style="position: static">
					<template v-if="outofstock.length > 0">
						<v-list
							v-for="(row, index) in outofstock"
							:key="index"
							class="mx-2 mt-4 mx-md-4 border border-danger red lighten-5 position-relative"
							subheader
							three-line
						>
							<div class="px-3 py-1 font-size-14 cart-item-type red--text darken-4">
								<v-icon color="red" size="18">mdi-alert</v-icon>
								This <span class="blue--text"> Asset</span> out of stock
							</div>
							<v-list-item>
								<v-list-item-avatar size="80">
									<ImageTemplate circle :src="row.image"></ImageTemplate>
								</v-list-item-avatar>
								<v-list-item-content class="col-4" v-if="row">
									<v-list-item-title class="font-size-16">
										<Chip :text="row.barcode"></Chip>
									</v-list-item-title>
									<v-list-item-subtitle class="font-size-14">
										<b>{{ row.related_name }}</b>
									</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-content class="col-6 d-flex" v-if="row">
									<v-list-item-subtitle class="font-size-14">
										<b>Return on :</b>
										<span class="fw-500 mr-1"> {{ row.reserve_to_formatted }}</span>
									</v-list-item-subtitle>
									<v-list-item-subtitle class="font-size-14">
										<b>Duration :</b>
										<span class="fw-500 mr-1"> {{ row.duration }}</span>
									</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-action class="col-2 justify-end" v-if="row">
									<v-list-item-action-text class="font-size-16">
										<v-tooltip top content-class="custom-top-tooltip">
											<template v-slot:activator="{ on, attr }">
												<v-btn
													v-on="on"
													v-bind="attr"
													v-on:click="deleteConfirm(row.uuid, row.related_name)"
													depressed
													color="red white--text"
													fab
													small
												>
													<v-icon>mdi-delete</v-icon>
												</v-btn>
											</template>
											<span>Delete</span>
										</v-tooltip>
									</v-list-item-action-text>
								</v-list-item-action>
							</v-list-item>
						</v-list>
					</template>
					<div
						v-if="checkouts.length > 0"
						class="mx-2 mt-4 mx-md-4 border border-secondary pb-3 position-relative"
					>
						<div class="px-3 py-1 font-size-14 cart-item-type orange--text darken-4">Issue</div>
						<template v-for="(row, index) in checkouts">
							<v-list
								:key="index"
								class="mx-2 mt-4 mx-md-4 border border-secondary blue lighten-5"
								subheader
								three-line
							>
								<v-list-item>
									<v-list-item-avatar size="80">
										<ImageTemplate circle :src="row.image"></ImageTemplate>
									</v-list-item-avatar>
									<v-list-item-content class="col-4" v-if="row">
										<v-list-item-title class="font-size-16">
											<Chip :text="row.barcode"></Chip>
										</v-list-item-title>
										<v-list-item-subtitle class="font-size-14">
											<b>{{ row.related_name }}</b>
										</v-list-item-subtitle>
									</v-list-item-content>
									<v-list-item-content class="col-6 d-flex" v-if="row">
										<template v-if="row.related_type != 'Inventory'">
											<div class="font-size-14">
												<b>From :</b>
												<span class="fw-500 mr-1"> {{ row.reserve_from_formatted }}</span>
											</div>
											<div class="font-size-14">
												<b>To :</b>
												<span class="fw-500 mr-1"> {{ row.reserve_to_formatted }}</span>
											</div>
										</template>
										<div class="font-size-14">
											<b>Quantity :</b>
											<span class="fw-500 mr-1"> {{ row.quantity }}</span>
										</div>
										<table v-if="false" width="100%">
											<tr v-for="(attachment, index) in row.attachment" :key="index">
												<td>
													<v-btn v-on:click="downloadFile(attachment)" color="blue darken-4" text
														><v-icon class="mr-2">mdi-attachment</v-icon> {{ attachment.name }}</v-btn
													>
												</td>
											</tr>
										</table>
									</v-list-item-content>
									<v-list-item-action class="col-2 justify-end" v-if="row">
										<v-list-item-action-text class="font-size-16">
											<v-tooltip v-if="false" top content-class="custom-top-tooltip">
												<template v-slot:activator="{ on, attr }">
													<v-btn
														v-on="on"
														v-bind="attr"
														depressed
														color="blue darken-4 white--text"
														class="mr-3"
														v-on:click="updateDialog(row.uuid, 'checkout')"
														fab
														small
													>
														<v-icon>mdi-pencil</v-icon>
													</v-btn>
												</template>
												<span>Edit</span>
											</v-tooltip>
											<v-tooltip top content-class="custom-top-tooltip">
												<template v-slot:activator="{ on, attr }">
													<v-btn
														v-on="on"
														v-bind="attr"
														v-on:click="deleteConfirm(row.uuid, row.related_name)"
														depressed
														color="red white--text"
														fab
														small
													>
														<v-icon>mdi-delete</v-icon>
													</v-btn>
												</template>
												<span>Delete</span>
											</v-tooltip>
										</v-list-item-action-text>
									</v-list-item-action>
								</v-list-item>
							</v-list>
						</template>
					</div>
					<div
						v-if="reservation.length > 0"
						class="mx-2 mt-4 mx-md-4 border border-secondary pb-3 position-relative"
					>
						<div class="px-3 py-1 font-size-14 cart-item-type blue--text darken-4">Reservation</div>
						<v-list
							class="mx-2 mt-4 mx-md-4 border border-secondary blue lighten-5"
							subheader
							three-line
							v-for="(row, index) in reservation"
							:key="index"
						>
							<v-list-item>
								<v-list-item-avatar size="80">
									<ImageTemplate circle :src="row.image"></ImageTemplate>
								</v-list-item-avatar>
								<v-list-item-content class="col-4" v-if="row">
									<v-list-item-title class="font-size-16">
										<Chip :text="row.barcode"></Chip>
									</v-list-item-title>
									<v-list-item-subtitle class="font-size-14">
										<b>{{ row.related_name }}</b>
									</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-content class="col-6 d-flex" v-if="row">
									<v-list-item-subtitle class="font-size-14">
										<b>From :</b>
										<span class="fw-500 mr-1"> {{ row.reserve_from_formatted }}</span>
									</v-list-item-subtitle>
									<v-list-item-subtitle class="font-size-14">
										<b>To :</b>
										<span class="fw-500 mr-1"> {{ row.reserve_to_formatted }}</span>
									</v-list-item-subtitle>
									<v-list-item-subtitle class="font-size-14">
										<b>Duration :</b>
										<span class="fw-500 mr-1"> {{ relatedDetails.duration }}</span>
									</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-action class="col-2 justify-end" v-if="row">
									<v-list-item-action-text class="font-size-16">
										<v-tooltip v-if="false" top content-class="custom-top-tooltip">
											<template v-slot:activator="{ on, attr }">
												<v-btn
													v-on="on"
													v-bind="attr"
													depressed
													color="blue darken-4 white--text"
													class="mr-3"
													fab
													v-on:click="updateDialog(row.uuid, 'reservation')"
													small
												>
													<v-icon>mdi-pencil</v-icon>
												</v-btn>
											</template>
											<span>Edit</span>
										</v-tooltip>
										<v-tooltip top content-class="custom-top-tooltip">
											<template v-slot:activator="{ on, attr }">
												<v-btn
													v-on="on"
													v-bind="attr"
													v-on:click="deleteConfirm(row.uuid, row.related_name)"
													depressed
													color="red white--text"
													fab
													small
												>
													<v-icon>mdi-delete</v-icon>
												</v-btn>
											</template>
											<span>Delete</span>
										</v-tooltip>
									</v-list-item-action-text>
								</v-list-item-action>
							</v-list-item>
						</v-list>
					</div>
					<div
						v-if="checkin.length > 0"
						class="mx-2 mt-4 mx-md-4 border border-secondary pb-3 position-relative"
					>
						<div class="px-3 py-1 font-size-14 cart-item-type green--text darken-4">Receive</div>
						<v-list
							class="mx-2 mt-4 mx-md-4 border border-secondary blue lighten-5"
							subheader
							three-line
							v-for="(row, index) in checkin"
							:key="index"
						>
							<v-list-item>
								<v-list-item-avatar size="80">
									<ImageTemplate circle :src="row.image"></ImageTemplate>
								</v-list-item-avatar>
								<v-list-item-content class="col-4" v-if="row">
									<v-list-item-title class="font-size-16">
										<Chip :text="row.barcode"></Chip>
									</v-list-item-title>
									<v-list-item-subtitle class="font-size-14">
										<b>{{ row.related_name }}</b>
									</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-content class="col-6 d-flex" v-if="row">
									<v-list-item-subtitle class="font-size-14">
										<b>Issue Date :</b>
										<span class="fw-500 mr-1"> {{ row.reserve_from_formatted }}</span>
									</v-list-item-subtitle>
									<v-list-item-subtitle class="font-size-14">
										<b>Receive Date :</b>
										<span class="fw-500 mr-1"> {{ row.reserve_to_formatted }}</span>
									</v-list-item-subtitle>
									<v-list-item-subtitle v-if="row.duration" class="font-size-14">
										<b>Duration :</b>
										<span class="fw-500 mr-1"> {{ row.duration }}</span>
									</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-action class="col-2 justify-end" v-if="row">
									<v-list-item-action-text class="font-size-16">
										<v-tooltip v-if="false" top content-class="custom-top-tooltip">
											<template v-slot:activator="{ on, attr }">
												<v-btn
													v-on="on"
													v-bind="attr"
													depressed
													color="blue darken-4 white--text"
													class="mr-3"
													fab
													v-on:click="updateDialog(row.uuid, 'checkin')"
													small
												>
													<v-icon>mdi-pencil</v-icon>
												</v-btn>
											</template>
											<span>Edit</span>
										</v-tooltip>
										<v-tooltip top content-class="custom-top-tooltip">
											<template v-slot:activator="{ on, attr }">
												<v-btn
													v-on="on"
													v-bind="attr"
													depressed
													v-on:click="deleteConfirm(row.uuid, row.related_name)"
													color="red white--text"
													fab
													small
												>
													<v-icon>mdi-delete</v-icon>
												</v-btn>
											</template>
											<span>Delete</span>
										</v-tooltip>
									</v-list-item-action-text>
								</v-list-item-action>
							</v-list-item>
						</v-list>
					</div>
					<div
						v-if="retired.length > 0"
						class="mx-2 mt-4 mx-md-4 border border-secondary pb-3 position-relative"
					>
						<div class="px-3 py-1 font-size-14 cart-item-type red--text darken-4">Retire</div>
						<v-list
							class="mx-2 mt-4 mx-md-4 border border-secondary blue lighten-5"
							subheader
							three-line
							v-for="(row, index) in retired"
							:key="index"
						>
							<v-list-item>
								<v-list-item-avatar size="80">
									<ImageTemplate circle :src="row.image"></ImageTemplate>
								</v-list-item-avatar>
								<v-list-item-content class="col-4" v-if="row">
									<v-list-item-title class="font-size-16">
										<Chip :text="row.barcode"></Chip>
									</v-list-item-title>
									<v-list-item-subtitle class="font-size-14">
										<b>{{ row.related_name }}</b>
									</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-content class="col-6 d-flex" v-if="row">
									<v-list-item-subtitle class="font-size-14" v-if="row.retired_date_formatted">
										<b>Retired Date :</b>
										<span class="fw-500 mr-1"> {{ row.retired_date_formatted }}</span>
									</v-list-item-subtitle>
									<v-list-item-subtitle class="font-size-14" v-if="row.quantity && row.quantity > 0">
										<b>Quantity :</b>
										<span class="fw-500 mr-1"> {{ row.quantity }}</span>
									</v-list-item-subtitle>
									<v-list-item-subtitle class="font-size-14">
										<b>Retire Reason :</b>
										<span class="fw-500 mr-1"> {{ row.retire_reason }}</span>
									</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-action class="col-2 justify-end" v-if="row">
									<v-list-item-action-text class="font-size-16">
										<v-tooltip v-if="false" top content-class="custom-top-tooltip">
											<template v-slot:activator="{ on, attr }">
												<v-btn
													v-on="on"
													v-bind="attr"
													depressed
													color="blue darken-4 white--text"
													class="mr-3"
													fab
													v-on:click="updateDialog(row.uuid, 'retire')"
													small
												>
													<v-icon>mdi-pencil</v-icon>
												</v-btn>
											</template>
											<span>Edit</span>
										</v-tooltip>
										<v-tooltip top content-class="custom-top-tooltip">
											<template v-slot:activator="{ on, attr }">
												<v-btn
													v-on="on"
													v-bind="attr"
													depressed
													v-on:click="deleteConfirm(row.uuid, row.related_name)"
													color="red white--text"
													fab
													small
												>
													<v-icon>mdi-delete</v-icon>
												</v-btn>
											</template>
											<span>Delete</span>
										</v-tooltip>
									</v-list-item-action-text>
								</v-list-item-action>
							</v-list-item>
						</v-list>
					</div>
				</perfect-scrollbar>
			</v-col>
			<v-col cols="12" lg="3" class="">
				<div
					class="mx-2 py-2 mt-0 mx-md-4 d-flex flex-wrap flex-column justify-content-between border border-secondary h-100"
				>
					<div class="px-2">
						<div class="font-size-16 px-3 py-3 mb-2 blue lighten-5 d-flex">
							<div class="fw-500">Total Issue</div>
							<v-spacer></v-spacer>
							<div
								color=""
								class="text-center px-1 orange white--text rounded-sm"
								label
								style="min-width: 30px"
							>
								{{ checkouts.length }}
							</div>
						</div>
						<div class="font-size-16 mb-2 px-3 py-3 blue lighten-5 d-flex">
							<div class="fw-500">Total Reservation</div>
							<v-spacer></v-spacer>
							<div>
								<div
									color=""
									class="text-center px-1 orange white--text rounded-sm"
									label
									style="min-width: 30px"
								>
									{{ reservation.length }}
								</div>
							</div>
						</div>
						<div class="font-size-16 mb-2 px-3 py-3 blue lighten-5 d-flex">
							<div class="fw-500">Total Receive</div>
							<v-spacer></v-spacer>
							<div
								color=""
								class="text-center px-1 orange white--text rounded-sm"
								label
								style="min-width: 30px"
							>
								{{ checkin.length }}
							</div>
						</div>
						<div class="font-size-16 mb-2 px-3 py-3 blue lighten-5 d-flex">
							<div class="fw-500">Total Retired</div>
							<v-spacer></v-spacer>
							<div
								color=""
								class="text-center px-1 orange white--text rounded-sm"
								label
								style="min-width: 30px"
							>
								{{ retired.length }}
							</div>
						</div>
					</div>
				</div>
			</v-col>
		</v-row>
		<Dialog :dialog="close_dialog">
			<template v-slot:title> Send Emial </template>
			<template v-slot:body>
				<v-row>
					<v-col cols="3" class="my-auto">
						<label class="font-weight-500 font-size-16 required">Recipients</label>
					</v-col>
					<v-col cols="9">
						<v-autocomplete
							outlined
							hide-details
							:items="recipients"
							v-model="recipient"
						></v-autocomplete>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="3" class="my-auto">
						<label class="font-weight-500 font-size-16">CC</label>
					</v-col>
					<v-col cols="9">
						<v-autocomplete outlined hide-details :items="cc" v-model="cc"></v-autocomplete>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="3" class="my-auto">
						<label class="font-weight-500 font-size-16">Subject</label>
					</v-col>
					<v-col cols="9">
						<v-text-field outlined hide-details v-model="subject"></v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="3">
						<label class="font-weight-500 font-size-16">Description</label>
					</v-col>
					<v-col cols="9">
						<v-textarea outlined hide-details v-model="description"></v-textarea>
					</v-col>
				</v-row>
			</template>
			<template v-slot:action>
				<v-btn tile depressed v-on:click="close_dialog = false">Cancel</v-btn>
				<v-btn tile depressed color="blue darken-4 white--text">Send</v-btn>
			</template>
		</Dialog>

		<template v-if="checkoutDialog">
			<CheckoutTemplate
				:type="relatedType"
				:endpoint="relatedEndPoint"
				:type-uuid="relatedUuid"
				:uuid="uuid"
				:cart-uuid="cartUuid"
				v-on:success="checkoutSuccess()"
				v-on:close="checkoutDialog = false"
				:checkout-dialog="checkoutDialog"
			></CheckoutTemplate>
		</template>
		<template v-if="selectRelateddialog">
			<SelectRequested
				v-on:selectRelatedTo="selectRelatedTo"
				v-on:close="selectRelateddialog = false"
				:checkout-dialog="selectRelateddialog"
				:type="requestType"
			></SelectRequested>
		</template>
		<template v-if="checkinDialog">
			<CheckinTemplate
				:type="relatedType"
				:endpoint="relatedEndPointCheckIn"
				:type-uuid="relatedUuid"
				:cart-uuid="cartUuid"
				:checkout-uuid="checkOutUuid"
				v-on:success="checkinSuccess()"
				v-on:close="checkinDialog = false"
				:checkin-dialog="checkinDialog"
			></CheckinTemplate>
		</template>
		<template v-if="reservationDialog">
			<ReservationTemplate
				:type="relatedType"
				:endpoint="relatedEndPoint"
				:cart-uuid="cartUuid"
				:type-uuid="relatedUuid"
				:uuid="uuid"
				v-on:success="
					reservationDialog = false;
					relatedUuid = null;
					relatedType = null;
					relatedEndPoint = null;
					getCart();
				"
				v-on:close="reservationDialog = false"
				:reservation-dialog="reservationDialog"
			></ReservationTemplate>
		</template>
		<template v-if="relatedUuid && selectCheckoutDialog">
			<SelectCheckout
				v-on:selectCheckout="
					(param) => {
						selectCheckoutDialog = false;
						selectCheckout(param);
					}
				"
				v-on:close="selectCheckoutDialog = false"
				:asset-stock-uuid="relatedUuid"
				:checkout-dialog="selectCheckoutDialog"
			></SelectCheckout>
		</template>
		<Dialog :dialog="deleteDialog" :dialog-width="640">
			<template v-slot:title> Delete Cart Item</template>
			<template v-slot:body>
				<v-row class="delete-dialog">
					<v-col md="2" class="py-0 text-right my-auto">
						<span class="svg-icon svg-icon-lg delete-confirm-icon">
							<!--begin::Svg Icon-->
							<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
							<!--end::Svg Icon-->
						</span>
					</v-col>
					<v-col md="10" class="py-0 my-auto">
						<p class="btx-p m-0">
							Are you sure you want to remove
							<span class="red--text font-bolder lighten-1">{{ deleteText }}</span> from cart ?
						</p>
					</v-col>
				</v-row>
			</template>
			<template v-slot:action>
				<v-btn
					class="white--text"
					:loading="deleteButton"
					:disabled="deleteButton"
					depressed
					color="red lighten-1"
					tile
					v-on:click="deleteCartRow"
				>
					Yes! Remove
				</v-btn>
				<v-btn depressed tile :disabled="deleteButton" v-on:click="deleteDialog = false">
					No, Close
				</v-btn>
			</template>
		</Dialog>
		<Dialog :dialog="proceedDialog" :dialog-width="640">
			<template v-slot:title> Proceed Cart</template>
			<template v-slot:body>
				<v-row class="delete-dialog">
					<v-col md="2" class="py-0 text-right my-auto">
						<span class="svg-icon svg-icon-lg delete-confirm-icon">
							<!--begin::Svg Icon-->
							<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
							<!--end::Svg Icon-->
						</span>
					</v-col>
					<v-col md="10" class="py-0 my-auto">
						<p class="btx-p m-0">Are you sure you want to proceed cart ?</p>
					</v-col>
				</v-row>
			</template>
			<template v-slot:action>
				<v-btn
					class="white--text"
					:loading="pageLoading"
					:disabled="pageLoading"
					depressed
					color="green lighten-1"
					tile
					v-on:click="proceedCart"
				>
					Yes! Proceed
				</v-btn>
				<v-btn depressed tile :disabled="pageLoading" v-on:click="proceedDialog = false">
					No, Close
				</v-btn>
			</template>
		</Dialog>
	</v-sheet>
</template>
<script>
import ImageTemplate from "@/view/components/Image";
import Chip from "@/view/components/Chip";
import Dialog from "@/view/components/Dialog.vue";
import { GetCart, ProceedCart, DeleteCart } from "@/core/lib/cart.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import CheckoutTemplate from "@/view/components/CheckoutTemplate";
import SelectRequested from "@/view/components/Select-Checkout-Requested";
import CheckinTemplate from "@/view/components/CheckinTemplate";
import ReservationTemplate from "@/view/components/ReservationTemplate";
import SelectCheckout from "@/view/components/CheckoutListTemplate";
import { CART_COUNT } from "@/core/services/store/config.module";
import store from "@/core/services/store";
import { EventBus } from "@/core/event-bus/event.bus";

export default {
	name: "Issue",
	components: {
		ImageTemplate,
		Chip,
		Dialog,
		CheckoutTemplate,
		SelectRequested,
		SelectCheckout,
		CheckinTemplate,
		ReservationTemplate,
	},
	data() {
		return {
			dataTab: "checkout",
			listRefreshed: false,
			close_dialog: false,
			checkoutDialog: false,
			user: {},
			reservationDialog: false,
			proceedDialog: false,
			pageLoading: false,
			deleteDialog: false,
			cartUuid: null,
			description: null,
			subject: null,
			cc: [],
			recipient: [],
			recipients: [],
			selectRelateddialog: false,
			deleteButton: false,
			relatedUuid: null,
			uuid: null,
			checkOutUuid: null,
			relatedType: null,
			selectCheckoutDialog: false,
			requestType: null,
			relatedUuidCheckIn: null,
			relatedTypeCheckIn: null,
			relatedEndPointCheckIn: null,
			checkinDialog: false,
			signature: null,
			reservation: [],
			checkouts: [],
			checkin: [],
			outofstock: [],
			retired: [],
			relatedDetails: {
				barcode: "AS-00004",
				name: "ALIENWARE M15 R6 GAMING LAPTOP",
				pico_barcode: null,
				price_add_stock: 100,
				cost_price_formatted: "$100.00",
				return_on: "30/07/2022  10:23",
				duration: "1 Week",
				checkout_to: "Super Admin",
			},
			deleteUUID: null,
			deleteText: null,
		};
	},
	methods: {
		downloadFile(file) {
			window.open(file.url, "_blank");
		},
		checkoutSuccess() {
			this.checkoutDialog = false;
			this.uuid = null;
			this.relatedUuid = null;
			this.relatedType = null;
			this.relatedEndPoint = null;
			this.getCart();
		},
		checkinSuccess() {
			this.checkinDialog = false;
			this.checkOutUuid = null;
			this.relatedUuid = null;
			this.relatedType = null;
			this.relatedEndPointCheckIn = null;
			this.getCart();
		},
		updateDialog(uuid, type) {
			this.cartUuid = uuid;
			switch (type) {
				case "checkout":
					this.checkoutDialog = true;
					break;
				case "checkin":
					this.checkinDialog = true;
					break;
				case "reservation":
					this.reservationDialog = true;
					break;
				case "retire":
					this.checkoutDialog = true;
					break;
			}
			//this.checkoutDialog = true;
		},
		deleteConfirm(uuid, assetName) {
			this.deleteDialog = true;
			this.deleteUUID = uuid;
			this.deleteText = assetName;
		},
		getCheckOut(data) {
			if (data.type == "Asset") {
				this.checkOutUuid = data.checkout_uuid;
				this.relatedEndPointCheckIn = "asset";
				this.checkinDialog = true;
			} else {
				this.relatedEndPointCheckIn = "asset-stock";
				this.selectCheckoutDialog = true;
			}
		},
		selectCheckout(param) {
			this.checkOutUuid = param.uuid;
			this.checkinDialog = true;
		},
		proceedCart() {
			const _this = this;
			this.pageLoading = true;
			ProceedCart(_this.listRefreshed)
				.then((data) => {
					if (data && data.status) {
						_this.proceedDialog = false;
						store.dispatch(CART_COUNT);
						_this.getCart();
						_this.$router.push({ name: "orders" });
					} else {
						if (data && data.cartData) {
							_this.listRefreshed = true;
							_this.reservation = data.cartData.reservation;
							_this.checkouts = data.cartData.checkout;
							_this.checkin = data.cartData.checkin;
							_this.outofstock = data.cartData.outOfStock;
						}
					}
				})
				.catch((error) => {
					_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		getCart() {
			const _this = this;
			GetCart()
				.then(({ reservation, checkout, checkin, outOfStock, retired, user }) => {
					_this.reservation = reservation;
					_this.checkouts = checkout;
					_this.checkin = checkin;
					_this.retired = retired;
					_this.user = user;
					_this.outofstock = outOfStock;
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		deleteCartRow() {
			const _this = this;
			this.deleteButton = true;
			DeleteCart(this.deleteUUID)
				.then(() => {
					_this.deleteDialog = false;
					_this.$store.dispatch(CART_COUNT);
					_this.getCart();
				})
				.catch((error) => {
					_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					_this.pageLoading = false;
					this.deleteButton = false;
				});
		},
		selectRelatedTo(data) {
			this.relatedUuid = data.id;
			this.relatedType = data.type;
			this.relatedEndPoint = data.endpoint;
			if (this.relatedUuid && this.relatedType && this.relatedEndPoint) {
				this.selectRelateddialog = false;
				switch (this.requestType) {
					case "reservation":
						this.reservationDialog = true;
						break;
					case "checkout":
						this.checkoutDialog = true;
						break;
					case "checkin":
						this.getCheckOut(data);
						//this.checkinDialog = true;
						break;
				}
			}
		},
	},
	mounted() {
		this.getCart();
		EventBus.$on("reload:cart", () => {
			this.checkoutSuccess();
			this.checkinSuccess();
		});
	},
};
</script>
